import '../App.css';
export default function Contact() {
    return (
        <div>
			<details><summary>06 contact</summary>
			<ul>
				<p>• aceschen [at] ucsc [dot] edu</p>
				<p>• <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/aceschen">linkedin</a></p>
				<p>• <a target="_blank" rel="noreferrer" href="https://www.figma.com/@ace">figma</a></p>
				<p>• <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1OmLfUcbtyVqZECb6NLGJA8AnNKyftato/view?usp=sharing">CV</a></p>
				{/* <p>• ... or on <a href="https://read.cv/ace">read.cv</a></p> */}
				</ul>
        	</details>
        </div>
        
    )

}