// import blahaj from './images/blahaj.png';
// import kirby from './images/kirb.png';
import './App.css';
import React, { useState } from "react";
import HealthyCorners from './components/healthycorners';
import FigmaGrowth from './components/figmagrowth';
import PawsUp from './components/pawsup';
import Yodeai from './components/yodeai';
import MERL from './components/merl';
import BiD from './components/bid';
import PFIWH from './components/sidequests/pfiwh';
import STUFF from './components/sidequests/stuff';
import ArtExhibit from './components/sidequests/artexhibit';
import Hands from './components/sidequests/hands';
import Config from './components/sidequests/config';
import AnimalCrossing from './components/sidequests/animalcrossing';
import EversonManor from './components/sidequests/eversonmanor';
import Indent from './components/sidequests/indent';
import LateWedding from './components/sidequests/latewedding';
import HouseofLeaves from './components/sidequests/houseofleaves';
import Homestuck from './components/sidequests/homestuck';
import Lovers from './components/sidequests/lovers';
import NameField from './components/sidequests/namefield';
import IntoTheWoods from './components/sidequests/intothewoods';

import Contact from './sections/contact';
import Postscript from './sections/postscript';

import {
  BrowserRouter,
  // Routes, //replaces "Switch" used till v5
  // Route,
} from "react-router-dom";

// https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

function App() {
  // https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

  // https://stackoverflow.com/questions/70938423/render-image-on-button-click-in-react
  const [itemClicked, setItemClicked] = useState({
    // importAll: false,
    // healthycorners : false, 
    // figmagrowth : false,
    // pawsup : false
  });
  const onClickHandler = (order) => {
    const resetItems = {
      // importAll: false, 
      // healthycorners: false, 
      // figmagrowth : false, 
      // pawsup : false
      // idk why this works to be honest
    }
    setItemClicked((prevState) => ({
      ...resetItems,
      [order]: !prevState[order]
    }));
  }

  return (
    <div className="App">
      <BrowserRouter>
        <header className="home"> hi, welcome in!</header>
        <body className="home">
       

        <details><summary> 01 about</summary>
          <p >I’m <span className="stuff" onClick={() => onClickHandler("ace")}>Ace S Chen</span>
          &nbsp;(he/him), a queer trans researcher + designer + technologist who's interested in human experiences, 
          new media technologies, and how we tell stories. </p>
          <p>Currently, I'm a first year Master's student in Computational Media at UC Santa Cruz, advised by Dr. Kate Ringland in 
            the <a target="_blank" rel="noreferrer" href="https://www.misfit-lab.com">Misfit Lab</a>. 
            </p>
          <p>I recently finished my undergrad at UC Berkeley studying computer science with a minor in theater + performance studies.
            I like making big pots of soup and eating ice cream with my friends.
          I also like lists. </p> 
          <p> On October 13, 2024, I was working on this website and thinking about Norco, puberty, and washing my hair.</p>
          {itemClicked.ace && <img class='itemImage' src={images['ace.jpg']} title="i don't actually have a mullet anymore, i'll update this soon" alt="simple marker drawing of a face with glasses and a mullet" />}
        </details>

        

        <details><summary>02 work</summary>
          <ul>
            <details><summary>research</summary><ul>
              <p>• <span className="stuff" onClick={() => onClickHandler("merl")}>Media Education Research Lab</span></p>
              {itemClicked.merl && <MERL />}
              <p>• <span className="stuff" onClick={() => onClickHandler("yodeai")}>Yodeai</span></p>
              {itemClicked.yodeai && <Yodeai />}
              <p>• <span className="stuff" onClick={() => onClickHandler("bid")}>Berkeley Institute of Design</span></p>
              {itemClicked.bid && <BiD />}
            </ul></details>

            <details><summary>design</summary><ul>
              <p>• <span className="stuff" onClick={() => onClickHandler("healthycorners")}>Healthy Corners Rewards</span></p>
              {itemClicked.healthycorners && <HealthyCorners />}
              <p>• <span className="stuff" onClick={() => onClickHandler("figmagrowth")}>Product Design Intern, Growth @ Figma</span></p>
              {itemClicked.figmagrowth && <FigmaGrowth />}
              <p>• <span className="stuff" onClick={() => onClickHandler("pawsup")}>Paws Up Redesign</span></p>
              {itemClicked.pawsup && <PawsUp />}
            </ul></details>

          <details><summary>teaching</summary><ul>
            <p>• Co-instructor of <a target="_blank" rel="noreferrer" href="https://www.cs160su23.com">CS160</a> at UC Berkeley (Summer 2023)</p>
            <p>• Creator + lead instructor of <a target="_blank" rel="noreferrer" href="https://www.bit.ly/figmadecal">Figma DeCal</a> (2021-2023)</p>
          </ul></details>            
          </ul>
        </details>       

        <details><summary>03 sidequests</summary><ul>

          <details><summary>design</summary><ul>
            <p>• <span className="stuff" onClick={() => onClickHandler("eversonmanor")}>The Mystery of Everson Manor</span></p>
            {itemClicked.eversonmanor && <EversonManor />}
            <p>• <span className="stuff" onClick={() => onClickHandler("animalcrossing")}>Animal Crossing Character Maker</span></p>
            {itemClicked.animalcrossing && <AnimalCrossing />}
            <p>• <span className="stuff" onClick={() => onClickHandler("config")}>Unexpected Places @ Config</span></p>
            {itemClicked.config && <Config />}
            <p>• <span className="stuff" onClick={() => onClickHandler("indent")}>How We Learn + How We Teach</span></p>
            {itemClicked.indent && <Indent />}
          </ul></details>

          <details><summary>theater</summary><ul>
            <p>• <span className="stuff" onClick={() => onClickHandler("houseofleaves")}>theatrical adaptation of House of Leaves</span></p>
            {itemClicked.houseofleaves && <HouseofLeaves />}
            <p>• <span className="stuff" onClick={() => onClickHandler("latewedding")}>costume assisting on The Late Wedding</span></p>
            {itemClicked.latewedding && <LateWedding />}
            <p>• <span className="stuff" onClick={() => onClickHandler("intothewoods")}>playing violin for Into the Woods</span></p>
            {itemClicked.intothewoods && <IntoTheWoods />}
          </ul></details>

          <details><summary>writing</summary><ul>
            <p>• <span className="stuff" onClick={() => onClickHandler("lovers")}>lovers.wiki</span></p>
            {itemClicked.lovers && <Lovers />}
            <p>• <span className="stuff" onClick={() => onClickHandler("namefield")}>NAME is a Required Field</span></p>
            {itemClicked.namefield && <NameField />}
          </ul></details>
          
          <details><summary>art</summary><ul>
            <p>• <span className="stuff" onClick={() => onClickHandler("STUFF")}>STUFF photo zine</span></p>
            {itemClicked.STUFF && <STUFF />}
            <p>• <span className="stuff" onClick={() => onClickHandler("artexhibit")}>Art Exhibit photo story</span></p>
            {itemClicked.artexhibit && <ArtExhibit />}
            <p>• <span className="stuff" onClick={() => onClickHandler("pfiwh")}>please forget i was her</span></p>
            {itemClicked.pfiwh && <PFIWH />}
          </ul></details>

          <details><summary>miscellany</summary><ul>
            <p>• <span className="stuff" onClick={() => onClickHandler("homestuck")}>talking about Homestuck in 2023</span></p>
            {itemClicked.homestuck && <Homestuck />}
            <p>• <span className="stuff" onClick={() => onClickHandler("hands")}>where to put my hands</span></p>
            {itemClicked.hands && <Hands />}  
          </ul></details>

          <details><summary>student orgs</summary><ul>
              <p>• <a target="_blank" rel="noreferrer" href="https://www.calblueprint.org">Cal Blueprint</a></p>
              <p>• <a target="_blank" rel="noreferrer" href="https://bit.ly/idot-club">IDOT</a></p>
            </ul></details>

        </ul></details>
        <details><summary>04 inventory</summary>
          <ul>
          <details><summary>backpack</summary> 
              <ul>
              <details><summary><span className="stuff" onClick={() => onClickHandler("pencilcase")}>pencil case</span></summary>
                    {itemClicked.pencilcase && <img class='itemImage' id='pencilcase' src={images['pencilcase.png']} title="i got this (custom stickered!) as a thank you gift for doing a workshop" alt="a clear pencil case full of colorful items, covered in cute little stickers" />}
                  <ul>
                    <p>• <a target="_blank" rel="noreferrer" href="https://www.dickblick.com/products/alvin-draft-line-mechanical-pencil/">0.5mm draftline pencil</a></p>
                    <p>• <a target="_blank" rel="noreferrer" href="https://www.aliexpress.us/item/3256801293655338.html?gatewayAdapt=glo2usa4itemAdapt">a bunch of soft tip brush pens i got in china</a></p>
                    <p>• <a target="_blank" rel="noreferrer" href="https://www.jetpens.com/Sun-Star-Ninipie-Dual-Tip-Highlighter-Yellow-Green-Green/pd/32060">sun-star ninipie dual tip highlighter in green</a></p>
                    <p>• assorted felt tip pens from muji</p>
                    <p>• a couple zebra mildliners</p>
                    <p>• plain old no.2 pencils</p>
                    <p>• stubby and crumbling white eraser</p>
                    <p>• wallet photos of my friends</p>
                    <p>• sticky tabs</p>
                  </ul>
                </details>
                <details><summary><span className="stuff" onClick={() => onClickHandler("orangepouch")}>orange pouch embroidered with flowers</span></summary>
                    {itemClicked.orangepouch && <img class='itemImage' id='orangepouch' src={images['orangepouch.png']} title="the perfect pouch.. my friend gifted it from vietnam" alt="an orange rectangular pouch with three zippered compartments, embroidered with pink and white flowers" />}
                  <ul>
                    <p>• small notebook</p>
                    <p>• sunscreen</p>
                    <p>• a whole bunch of lactaid</p>
                    <p>• motion sickness pills</p>
                    <p>• hairtie</p>
                  </ul>
                </details>
                <details><summary><span className="stuff" onClick={() => onClickHandler("yellowpouch")}>yellow pouch with black zipper</span></summary>
                    {itemClicked.yellowpouch && <img class='itemImage' id='yellowpouch' src={images['yellowpouch.png']} title="it's very flat so it doesn't fit much more than napkins" alt="a utilitarian yellow rectangular pouch with a black zipper" />}
                    <ul>
                    <p>• million chipotle napkins</p>
                  </ul>
                </details>
                <details><summary>laptop</summary>
            <ul>
              <p>• desktop</p>
              <p>• snipping tool</p>
              <p>• <a target="_blank" rel="noreferrer" href="https://open.spotify.com/user/12a.s.chen?si=98aad4fcac624586">spotify</a></p>
              <p>• <a target="_blank" rel="noreferrer" href="https://letterboxd.com/aceschen/">letterboxd</a></p>
              <p>• <a target="_blank" rel="noreferrer" href="https://www.are.na/ace-chen">arena</a></p>
              <p>• <a target="_blank" rel="noreferrer" href="http://aceschen.com">ace place</a></p>
              <details><summary>notepad</summary>
                <ul>
                  <details><summary>"about archive"</summary>
                  <ul>
                    <p>On August 22, 2024, I was thinking about fruit, cosplay competitions, and how I should probably get off my computer.</p>
                    <p>On August 21, 2024, I was thinking about watermelon, Edible Arrangements, and Italo Calvino.</p>
                    <p>On June 2, 2024, I was thinking about Hami melon, solitaire, and Glitzville.</p>
                    <p>on january 19, 2024, i was thinking about Buckyballs, Nimona, and watermelon.</p>
                    <p>on january 1, 2024, i was thinking about the new year, sweetie citrus, and watermelon.</p>
                    <p>on october 4, 2023, i was thinking about chicken pot pie, sunset, and what anime to watch.</p>
                    <p>on october 3, 2023, i was thinking about brushing my teeth, going to bed, and what coffeeshop i should go to tomorrow.</p>
                    <p>on september 29, 2023, i was thinking about mario party, soup, and the rodeo.</p>
                    <p>on september 16, 2023, i was thinking about cake, mitski, and sleepovers.</p>
                    <p>on july 7, 2023, i was thinking about document formatting, going to the gym, and the delicious peach i ate earlier.</p>
                    <p>on july 6, 2023, i was thinking about note-taking tools, tears of the kingdom, and sour candy.</p>
                    <p>on july 2, 2023, i was thinking about note-taking tools, tears of the kingdom, and watermelon.</p>
                  </ul>
                  </details>
                  <p>• "running notes"</p>
                  <p>• "classes maybe"</p>
                  <p>• "GMT20240124_RECORDING..."</p>
                  <p>• "ta stuff"</p>
                  <p>• "rental application info"</p>
                  <p>• "4.1.2 notes"</p>
                  <p>• "chat w b"</p>
                </ul>
              </details>
            </ul>
          </details>
                <details><summary>green folder</summary>
                  <ul>
                    <p>• class readings</p>
                    <p>• seminar discussion guides</p>
                    <p>• scenes from classmates' plays</p>
                    <p>• loose worksheets</p>
                  </ul>
                </details>
                <p>• brand new sketchbook</p>
              </ul>
          </details>
          
          <details><summary>tote bag</summary>
          <ul>
              <details><summary><span className="stuff" onClick={() => onClickHandler("whitepouch")}>white pouch with yellow zipper</span></summary>
                  {itemClicked.whitepouch && <img class='itemImage' id='whitepouch' src={images['whitepouch.png']} title="it comes everywhere with me" alt="a small off-white coinpurse three bell pepper-like characters making faces, secured with a ring-shaped zipper" />}
                <ul>
                  <p>• cheap purple wired earbuds</p>
                  <p>• burt's bees chapstick</p>
                  <p>• emergency advil + allergy meds</p>
                  <p>• 2 chewable lactaid tablets</p>
                </ul>
              </details>
              <p>• <span className="stuff" onClick={() => onClickHandler("nalgene")}>nalgene bottle covered in stickers</span></p>
              {itemClicked.nalgene && <img class='itemImage' id='nalgene' src={images['nalgene.png']} title="my sister drew all the little pigeons and had it custom made <3" alt="a translucent aqua water bottle with a brown cap and tons of stickers" />}
              <p>• <span className="stuff" onClick={() => onClickHandler("sanriobag")}>baggu x sanrio reusable bag</span></p>
              {itemClicked.sanriobag && <img class='itemImage' id='sanriobag' src={images['sanriobag.png']} title="she is very beautiful to me" alt="a blue and white checkered reusable bag with sanrio characters popping out of the squares" />}
              <p>• ipad</p>                
          </ul>
          </details>

          <details><summary>cross-body bag</summary>
          <ul>
          <details><summary>phone</summary>
                <ul>
                <details><summary>click to reveal my password</summary>
                <ul>
                  <p>• hey ...</p>
                </ul>
              </details>
                </ul>
              </details>
              <details><summary>wallet</summary>
                <ul>
                  <p>• clipper card</p>
                  <p>• berkeley public library card</p>
                  <p>• old red envelope</p>
                  <p>• knockoff university of maryland patch</p>
                  <p>• IDs and cards</p>
                </ul>
              </details>
              <details><summary><span className="stuff" onClick={() => onClickHandler("keys")}>keys</span></summary>
                    {itemClicked.keys && <img class='itemImage' id='keys' src={images['keys.png']} title="my partner gave me another epic charm but i haven't updated the drawing yet so just imagine it" alt="a carabiner with a set of keys and charms" />}
                  <ul>
                    <p>• black carabiner from my friend</p>
                    <p>• lucky charms from my partner</p>
                    <p>• kirby fabric charm from my sister</p>
                    <p>• apartment keys</p>
                    <p>• mystery key that says 888</p>
                  </ul>
                </details>
            <p>• mintia mints</p>
            <p>• hand sanitizer</p>
            <p>• packet of tissues</p>
            <p>• mask</p>
              
            <p></p>
            </ul></details>

          </ul>
        </details>
        
        <details><summary>05 overworld</summary>
          <ul>
            <details><summary>california</summary> 
            <ul>
              <details><summary>santa cruz</summary>
                <ul>
                  <details><summary>my apartment</summary> 
                  <ul>
                    <details><summary>bedroom</summary> 
                    <ul>
                      
                      <details><summary>desk</summary>
                        <ul>
                          <details><summary>trinkets</summary>
                            <ul>
                              <p>• super mario star washi tape holder</p>
                              <p>• bandana waddle dee nendoroid</p>
                              <p>• meta knight reading a book</p>
                              <p>• <a target="_blank" rel="noreferrer" href="https://nekostop.com/products/re-ment-kirby-of-the-stars-poyotto-collection">kirby poyotto collection figure</a></p>
                              <p>• <a target="_blank" rel="noreferrer" href="https://nekostop.com/products/re-ment-kirby-afternoon-tea">kirby afternoon tea figure (waddle dee butler)</a></p>
                              <p>• <a target="_blank" rel="noreferrer" href="https://blindboxempire.com/products/molinta-zhuodawang-ranch-journey?srsltid=AfmBOortljDJh4g2iwayaKwjve3pvrVU9YpyG9PYtmdeKY_tK8s0QUCJ">molinta ranch journey (love fruit)</a></p>
                            </ul>
                          </details>
                          <p>• keyboard</p>
                          <p>• mouse + mousepad</p>
                          <p>• green glass cup</p>
                          <p>• in-progress book</p>
                          <p>• <a target="_blank" rel="noreferrer" href="https://schylling.com/product/needoh-nice-cube/">needoh nice cube</a></p>
                        </ul>
                      </details>


                      {/* TODO: ADD IMAGE. GAME? CLOSET GAME? */}
                      <details><summary>closet</summary>
                            <ul>
                            <details><summary>tops</summary>
                              <ul>
                                <p>• green gingham button up</p>
                                <p>• white LAUNDRY DAY tour tee</p>
                                <p>• the greeting committee tour tee</p>
                                <p>• black oversized button up</p>
                                <p>• faded red GUESS quarter zip</p>
                              </ul>
                            </details>
                            <details><summary>bottoms</summary>
                              <ul>
                                <p>• off-white contrast stitching cargos</p>
                                <p>• olive nylon joggers</p>
                                <p>• dark gray pleated skirt</p>
                                <p>• heather gray sweatshorts</p>
                                <p>• black lounge shorts with little flowers</p>
                              </ul>
                            </details>
                            <details><summary>jackets</summary>
                              <ul>
                                <p>• bright red windbreaker</p>
                                <p>• black collared overshirt</p>
                                <p>• old gray bomber jacket</p>
                                <p>• off-white dijon hoodie</p>
                              </ul>
                            </details>
                            <details><summary>shoes</summary>
                              <ul>
                                <p>• new blanace numeric tiago lemos 1010</p>
                                <p>• vagabond tara boots</p>
                                <p>• doc marten 1460 boots</p>
                              </ul>
                            </details>
                            <details><summary>laundry hamper</summary>
                              <ul>
                                <details><summary>wait</summary>
                                  <ul>
                                    <details><summary>what's that?</summary>
                                    <ul>
                                    <details><summary>could it be...</summary>
                                      <ul>
                                        <p>• oh it's just lint</p>
                                      </ul>
                                    </details>
                                    </ul>
                                  </details>
                                  </ul>
                                </details>
                              </ul>
                            </details>
                            <details><summary>small crate</summary>
                              <ul>
                                <p>• testosterone</p>
                                <p>• needles</p>
                                <p>• syringes</p>
                                <p>• alcohol pads</p>
                                <p>• sharps bin</p>
                              </ul>
                            </details>
                            </ul>
                      </details>

                      <details><summary>shelf</summary>
                        <ul>
                          <p>• big mirror</p>
                          <p>• chinese money plant</p>
                          <p>• pothos clipping</p>
                          <p>• jellycat lemon</p>
                          <p>• lego chrysanthemum</p>
                          <p>• little red alarm clock</p>
                          <p>• mini-easel with art by my partner</p>
                          <details><summary>books</summary>
                            <ul>
                              <p>• Chain-Gang All-Stars by Nana Kwame Adjei-Brenyah</p>
                              <p>• If on a Winter's Night a Traveler by Italo Calvino</p>
                              <p>• Blockchain Chicken Farm by Xiaowei Wang</p>
                              <p>• Songs on Endless Repeat by Anthony Veansa So</p>
                              <p>• House of Leaves by Mark Z Danielewski</p>
                              <p>• Little Blue Encyclopedia (for Vivian) by Hazel Jane Plante</p>
                              <p>• Piranesi by Suzanna Clarke</p>
                              <p>• Scott Pilgrim by Bryan Lee O'Malley</p>
                            </ul>
                          </details>
                          <details><summary>christmas cookie tin</summary>
                            <ul>
                              <p>• letters</p>
                              <p>• cards</p>
                              <p>• artwork by friends</p>
                              <p>• other loving ephemera</p>
                            </ul>
                          </details>
                        </ul>
                      </details>

                      <details><summary>bed</summary>
                              <ul>
                                <p>• zzz... (im dreaming)</p>
                              </ul>
                            </details>
                      <p>• posters + art</p>
                    </ul>
                    </details>
                    <details><summary>living room</summary> 
                    <ul>
                      <details><summary>letter board</summary> 
                        <ul>
                          <p>• it currently says... nothing actually</p>
                        </ul>
                      </details>
                      <details><summary>comfortable couch</summary> 
                      <ul>
                        <p>• <span className="stuff" onClick={() => onClickHandler("blahaj")}>blåhaj</span></p>
                            {itemClicked.blahaj && <img class='itemImage' src={images['blahaj.png']} title="he's a little worse for wear now but still the best" alt="blue shark plush from IKEA" />}
                          <p>• <span className="stuff" onClick={() => onClickHandler("djungelskog")}>djungelskog</span></p>
                            {itemClicked.djungelskog && <img class='itemImage' src={images['djungelskog.png']} title="the perfect size for hugging" alt="brown bear plush from IKEA" />}
                        <p>• my roommate, maybe</p>
                        </ul>
                      </details>
                      <details><summary>wooden crate</summary> 
                      <ul>
                        <p>• more stuffed animals... <span className="stuff" onClick={() => onClickHandler("dragon")}>want one?</span></p>
                        {itemClicked.dragon && <img class='itemImage' src={images['dragon.png']} title="he can snoop around with you" alt="sage green fluffy dragon plush from jellycat" />}
                        </ul>
                      </details>
                      <details><summary>coffee table</summary> 
                        <ul>
                          <p>• fresh flowers in a pasta sauce jar</p>
                          <p>• mismatched coasters</p>
                          <p>• a couple mugs (not on the coasters)</p>
                        </ul>
                      </details>
                      <details><summary>nintendo switch</summary> 
                        <ul>
                          <p>• paper mario: the thousand year door</p>
                          <p>• tears of the kingdom</p>
                          <p>• hades</p>
                          <p>• tetris 99</p>
                          <p>• mario party superstars</p>
                        </ul>
                      </details>
                      <p>• guest book</p>
                    </ul>
                    </details>

                    <details><summary>kitchen</summary>
                        <ul>
                        <details><summary>fridge</summary>
                            <ul>
                              <p>• leftover stew from yesterday</p>
                              <p>• sandwich necessities</p>
                              <p>• jar of honey citron tea jelly</p>
                              <p>• napa cabbage</p>
                              <p>• chinese ricecakes</p>
                              <p>• red grapes</p>
                              <p>• orange-flavored jelly drink</p>
                            </ul>
                          </details>
                          <details><summary>freezer</summary>
                            <ul>
                              <p>• dino nuggets</p>
                              <p>• bibigo wontons</p>
                              <p>• boneless skinless chicken thighs</p>
                              <p>• 2 half-finished loaves of sliced bread</p>
                              <p>• <a target="_blank" rel="noreferrer" href="https://acechen.notion.site/5ae51135e1aa434ea43016565e1b6277?v=afa48dae555e4e20a8bfcd79f564837a&pvs=4">several pints of ice cream</a></p>
                            </ul>
                          </details>
                          <details><summary>cupboard (pantry)</summary>
                            <ul>
                              <p>• navel oranges</p>
                              <p>• potatoes</p>
                              <p>• a yellow onion</p>
                              <p>• 2 packs of neoguri ramen</p>
                              <p>• wumu dry noodles</p>
                              <p>• baking goods</p>
                              <p>• unopened chocolate bars</p>
                              <p>• offensively stale oreos</p>
                            </ul>
                          </details><details><summary>cupboard (dishware)</summary>
                            <ul>
                              <p>• corelle dishware set from my mom</p>
                              <p>• one huge tupperware</p>
                              <p>• metal hello kitty bento</p>
                              <p>• a "the cat returns" tea saucer</p>
                              <p>• cute bowl with a cat</p>
                              <p>• pink le creuset flower bowl</p>
                              <p>• two matching ramen bowls</p>
                              <p>• custom painted soup bowl</p>
                              <p>• handmade bisque bowl</p>
                              <p>• novelty fork with pink cowprint handle</p>
                            </ul>
                          </details>
                          <p>• microwave</p>
                          <p>• stove</p>
                          <p>• oven</p>
                        </ul>
                    </details>

                    <details><summary>bathroom</summary>
                            <ul>
                              <p>• hey that's private</p>
                            </ul>
                    </details>
                  </ul>
                  </details>
                  <details><summary>uc santa cruz</summary>
                    <ul>
                      <details><summary>engineering 2</summary>
                        <ul>
                          <p>• pursuing ms in computational media</p>
                        </ul>
                      </details>
                      <p>• lionel cantú queer resource center</p>
                      <p>• a reading room in mchenry library</p>
                    </ul>
                  </details>
                </ul>
              </details>
              <details><summary>berkeley</summary> 
              <ul>
                          
              <details><summary>uc berkeley</summary>
                <ul>
                <details><summary>soda hall</summary>
                  <ul><p>• BA in computer science</p> </ul>
                </details>
                <details><summary>zellerbach hall</summary>
                  <ul><p>• minor in theater and performance studies</p> </ul>
                </details>
                <details><summary>jacobs hall</summary>
                  <ul><p>• certificate in design innovation</p></ul>
                </details>
                <p>• a grassy spot behind cv starr east asian library</p>
                <p>• a table near where the ddr machine was in mlk</p>
                <p>• ishi court hidden inside dwinelle hall</p>
                </ul>
              </details>

              <details><summary>around the city</summary>
                <ul>
                  <p>• <a target="_blank" rel="noreferrer" href="https://linktr.ee/peoplespark">people's park</a></p>      
                  <p>• willard park</p>
                  <p>• yogurt park</p>
                  <details><summary>strawberry creek park</summary>
                    <ul>
                      <p>• the hidden cafe</p>
                    </ul>
                  </details>
                  <p>• <a target="_blank" rel="noreferrer" href="https://www.instagram.com/p/C7ct4KBSkk9/?utm_source=ig_web_copy_link">berkeley bowl</a></p>
                 
                  <p>• racha cafe</p>
                  <p>• mezzo</p>
                  <p>• meskie's ethiopia restaurant</p>
                  <p>• sodoi coffee tasting house</p>
                </ul>
              </details>
            </ul>
            </details>
            </ul>
            </details>

            <details><summary>other homes</summary> 
            <ul>
              <details><summary>texas</summary>
              <ul>
                <p>• rita's ice custard happiness</p>
                <p>• menchie's frozen yogurt</p>
                <p>• braum's</p>
                <p>• dippin dots at moody gardens</p>
              </ul>
              </details>
              <details><summary>maryland</summary>
              <ul>
                <p>• soft stuff ice cream</p>
                <p>• bmore licks</p>
                <p>• moo moo cows</p>
                <p>• the charmery</p>
                <p>• LUCKY'S</p>
                <p>• the baskin robbins near my high school</p>
              </ul>
              </details>
              <details><summary>michigan</summary>
              <ul>
                <p>• blank slate creamery</p>
                <p>• seeking further ice cream recommendations</p>
              </ul>
              </details>
            </ul>
            </details>
          </ul>
        </details>

        <Contact></Contact>

       <Postscript></Postscript>

        </body>
      </BrowserRouter>
    </div>
  );
}

export default App;
